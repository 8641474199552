import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// fetch("https://ipapi.co/json/")
//   .then((response) => response.json())
//   .then((data) => {
//     if (data.country_code === "US" || data.country_code === "CA") {
//       // Initialize and display the app if the user is in the United States
//       const root = ReactDOM.createRoot(document.getElementById("root"));
//       root.render(
//         <React.StrictMode>
//           <App />
//         </React.StrictMode>
//       );
//     } else {
//       document.body.innerHTML =
//         "Sorry, this application is been accessed from a forbidden IP Address.";
//     }
//   })
//   .catch((error) => {
//     console.error("Error determining location:", error);
//   });


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
